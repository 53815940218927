//
// _tooltip.scss
//


@each $color,
$value in $theme-colors {
    .#{$color}-tooltip {
        --#{$prefix}tooltip-bg: var(--#{$prefix}#{$color}) !important;
    }
}