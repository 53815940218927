//
// alert.scss
//

@each $color,
$value in $theme-colors {
    .alert-#{$color} {
        --#{$prefix}alert-color: #{shade-color($value, 5%)};
        --#{$prefix}alert-bg: #{rgba($value, 0.18)};
        --#{$prefix}alert-border-color: #{rgba($value, 0.25)};

        .alert-link {
            --#{$prefix}alert-color: shade-color($value, 30%);
        }
    }
}