//
// frappe-gantt.scss
//

.gantt {
    .bar-progress {
        fill: $primary;
    }
    .bar-wrapper:hover {
        .bar-progress {
            fill: lighten($primary, 5%);
        }
    }
    .bar,
    .bar-wrapper:hover .bar {
        fill: var(--#{$prefix}progress-bg);
    }
    .bar-label,
    .bar-label.big {
        fill: var(--#{$prefix}gray-700);
    }
    .grid-header {
        fill: $table-hover-bg;
        stroke: $table-hover-bg;
    }
    .grid-row {
        fill: transparent;
        &:nth-child(even) {
            fill: $table-hover-bg;
        }
    }
    .lower-text,
    .upper-text {
        fill: var(--#{$prefix}body-color);
    }
    .row-line {
        stroke: $table-border-color;
    }
    .today-highlight {
        fill: var(--#{$prefix}gray-200);
    }
}

.gantt-container {
    .popup-wrapper {
        .pointer {
            display: none;
        }
    }
}
